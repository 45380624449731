import { Link } from "@remix-run/react";
import { READ_FORMATS, WRITE_FORMATS } from "@konbert/ui/format";
import React from "react";
import { formatIconsComponents } from "./FormatIcons";

const FileConvertersGrid = ({
  title = "Data Converters",
  inputFormats = READ_FORMATS,
}) => {
  const outputFormats = WRITE_FORMATS;

  return (
    <>
      <h3 className="text-3xl lg:text-4xl font-bold font-display tracking-tight text-primary-800 mb-4 text-center pb-16">
        {title}
      </h3>
      <div className="container grid max-w-5xl grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-6 mx-auto">
        {inputFormats.map((input) => (
          <div
            key={input.id}
            className="group flex flex-col items-start rounded-lg border border-gray-200 bg-white p-6 shadow-sm transition-colors"
          >
            <div className="flex h-8 w-8 items-center justify-center mb-3">
              {formatIconsComponents[input.id]
                ? React.createElement(formatIconsComponents[input.id], {
                    className: "w-full h-full flex-shrink-0 block",
                  })
                : React.createElement(formatIconsComponents["csv"], {
                    className: "w-full h-full flex-shrink-0 block",
                  })}
            </div>
            <div className="mt-4 space-y-2">
              <h3 className="text-lg font-semibold pb-2">
                {input.title} Conversions
              </h3>
              <div className="flex flex-col gap-2">
                {outputFormats
                  .filter((output) => output.title !== input.title)
                  .map((output) => (
                    <Link
                      key={output.id}
                      to={`/convert/${input.id}/to/${output.id}`}
                      title={`Convert ${input.title} to ${output.title}`}
                      className="text-gray-500 hover:text-primary-500 dark:text-gray-400 dark:hover:text-primary-500"
                    >
                      {input.title} to {output.title}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FileConvertersGrid;
